import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import {REACT_APP_NODE_ENV} from "./constants/env";
import './lang';
import {ScrollProvider} from "./components/Scroll/Scroll";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import BackTop from "./pages/components/BackTop/BackTop";
import HomePage from "./pages/Home/HomePage";
import {getUserByToken} from "./store/slices/userSlice";
import FullLoading from "./components/LoadingComponents/FullLoading";
import LoginPage from "./pages/Login/LoginPage";
import Logout from "./pages/Logout/Logout";
import StatsPage from "./pages/Stats/StatsPage";

if (REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}

function App() {
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserByToken());
    }, []);

    if (isLoading) {
        return <FullLoading/>;
    }

    return (
        <ScrollProvider>
            <BackTop/>
            <Router>
                <Routes>
                    <Route path='/' element={user !== null ? <HomePage/> : <Navigate to="/login" replace />}/>
                    <Route path='/stats' element={user !== null ? <StatsPage/> : <Navigate to="/login" replace />}/>
                    <Route path='/login' element={user == null ? <LoginPage/> : <Navigate to="/" replace />}/>
                    <Route path='/logout' element={<Logout/>}/>
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
