import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const RegionService = {};
RegionService.getRegions = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/region/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

RegionService.getGovsOfRegion = async (codeName) => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/region/get/codeName/govs",
        params: {
            codeName
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default RegionService;