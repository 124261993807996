import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const StatsService = {};
StatsService.getCount = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/stats/get/count",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
StatsService.getByRegion = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/stats/get/region",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
StatsService.getByCountry = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/stats/get/country",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default StatsService;