import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Flex, message, Statistic} from "antd";
import CountUp from "react-countup";
import {CloudDownloadOutlined, HomeOutlined} from "@ant-design/icons";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import StatsService from "../../services/StatsService";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {clrs} from "../../constants/colors";

const StatsPage = () => {
    const navigate = useNavigate();
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [byRegion, setByRegion] = useState([]);
    const [count, setCount] = useState(0);
    const [byCountry, setByCountry] = useState([]);

    useEffect(() => {
        init()
    }, []);

    async function init() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await StatsService.getCount()
            .then((r) => {
                setCount(r.data);
            })
            .catch(() => {

            })
        await StatsService.getByRegion()
            .then((r) => {
                setByRegion(r.data);
            })
            .catch((r) => {

            })
        await StatsService.getByCountry()
            .then((r) => {
                setByCountry(r.data);
            })
            .catch((r) => {

            })
        message.destroy();
        setLoading(false);
    }

    if (user == null && !isLoading) {
        navigate('/login');
    }

    if (loading) {
        return '';
    }

    const formatter = (value) => <CountUp end={value} separator="," />;


    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Flex style={{width: "100%", maxWidth: 1920, paddingTop: 35}} vertical align={"center"} justify={"flex-start"} gap={50}>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={5}>
                    <Link to={'/'}>
                        <BlueButton>
                            <HomeOutlined />
                            <span> </span>
                            Главная
                        </BlueButton>
                    </Link>
                </Flex>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={5}>
                    <Statistic title="Количество" value={count} formatter={formatter} />
                </Flex>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={5}>
                    <PieCartRegion stats={byRegion}/>
                </Flex>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={5}>
                    <PieCartCountry stats={byCountry}/>
                </Flex>
            </Flex>
        </Flex>
    );
};

const PieCartRegion = ({stats}) => {
    const options = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Статистика по регионам'
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: true,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'Процент',
                colorByPoint: true,
                data: stats.map(stat => {
                    return {
                        name: stat.name,
                        y: stat.count,
                    }
                })
            }
        ]
    };

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

const PieCartCountry = ({stats}) => {
    const options = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Статистика по странам'
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: true,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'Процент',
                colorByPoint: true,
                data: stats.map(stat => {
                    return {
                        name: stat.name,
                        y: stat.count,
                    }
                })
            }
        ]
    };

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

export default StatsPage;