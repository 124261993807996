import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const RecordService = {};
RecordService.getRecords = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/record/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

RecordService.createRecord = async () => {
    return axios({
        method: "post",
        url: API_BASE_URL + "/api/record/create",
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

RecordService.deleteRecordById = async (id) => {
    return axios({
        method: "delete",
        url: API_BASE_URL + "/api/record/delete/" + id,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


RecordService.updateRecordById = async (id, data) => {
    return axios({
        method: "post",
        url: API_BASE_URL + "/api/record/update/" + id,
        data: data,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

RecordService.downloadList = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/record/download/excel",
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        responseType: "blob"
    });
}
export default RecordService;