import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const UserService = {};
UserService.getUserByToken = async () => {
    // let data = new FormData();
    // const token = localStorage.getItem(ACCESS_TOKEN);
    // data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/get/token",
        // data: data,
        headers: {
            // "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.register = async (
    username,
    password,
) => {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/register",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}
export default UserService;