import React, {useState} from 'react';
import {Flex, Form, Image, Input, message} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/AuthService";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import {getUserByToken} from "../../store/slices/userSlice";
import classes from '../global_styles.module.css';
import FormItem from "antd/es/form/FormItem";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import {clrs} from "../../constants/colors";
import MyText from "../../components/UI/Text/MyText";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import logo from '../../images/logo.png';
const LoginPage = () => {
    const [form] = Form.useForm();

    const username = Form.useWatch('username', form);
    const password = Form.useWatch('password', form);

    const navigate = useNavigate();
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    if (user !== null && !isLoading) {
        navigate('/');
    }

    function handleOnSubmit() {
        form.validateFields()
            .then(() => {
                AuthService.login(username, password)
                    .then((result) => {
                        // console.log(result.data);
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        localStorage.setItem('Authorization', result?.data.access_token);
                        localStorage.setItem('ExpirationDate', result?.data.expiration_date);
                        localStorage.setItem('Refresh', result?.data.refresh_token);
                        dispatch(getUserByToken());
                    })
                    .catch((result) => {
                        message.destroy();
                        if (result?.response.data === "user not found" || result?.response.data === "password is incorrect") {
                            message.error(<IntlMessage id={'incorrectPasswordOrUsername'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);

                        }
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"center"} justify={"center"} gap={50}>
                <Flex align={"center"} justify={"center"} gap={25}>
                    <Image preview={false} src={logo} width={75}/>
                    <Flex vertical gap={0} align={"flex-start"} justify={"flex-start"}>
                        <MyText strong style={{textAlign: "center", fontSize: "1.2rem"}}>
                            Информационная база апостилирования
                        </MyText>
                        <MyText strong style={{textAlign: "center", fontSize: "1.2rem"}}>
                            официальных документов,
                        </MyText>
                        <MyText strong style={{textAlign: "center", fontSize: "1.2rem"}}>
                            исходящих из организаций образования
                        </MyText>
                    </Flex>
                </Flex>

                <Flex style={{width: "100%", position: "relative", maxWidth: 500}}>
                    <Form
                        onKeyUp={(e) => {if (e.key === "Enter") {handleOnSubmit()}}}
                        form={form}
                        className={classes.form}
                        style={{justifyContent: "center"}}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="username">
                            <Input rootClassName={classes.formInput}
                                   placeholder={IntlMessageText.getText({id: "username"})}
                                   prefix={<UserOutlined style={{marginRight: 5}}/>}
                            />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="password">
                            <Input.Password rootClassName={classes.formInput}
                                            placeholder={IntlMessageText.getText({id: "password"})}
                                            prefix={<KeyOutlined style={{marginRight: 5}}/>}
                            />
                        </FormItem>
                        <FormItem className={classes.formInputBlock}>
                            <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                <BlueButton
                                    onClick={handleOnSubmit}>
                                    <IntlMessage id={'login'}/>
                                </BlueButton>
                            </Flex>
                        </FormItem>
                    </Form>
                </Flex>

            </Flex>

        </Flex>
    );
};

export default LoginPage;